import React, { useState, useEffect } from "react"
import classnames from "classnames"

function scrollLock() {
  // var elmnt = document.getElementById("free-trial");
  // var topValue = elmnt.scrollTop;

  document.body.style.overflow = "hidden"
  document.body.style.position = "fixed"
  // document.body.style.top = "intElemScrollTop";
  document.body.style.left = "0"
  document.body.style.right = "0"
}

function removeScrollLock() {
  document.body.style.overflow = "visible"
  document.body.style.position = "initial"
  // document.body.style.top = "initial";
  document.body.style.left = "initial"
  document.body.style.right = "initial"
}

const ScheduleADemoButton = props => {
  // eslint-disable-next-line
  const [overlay, setOverlay] = useState(false)

  // const closeModal = () => {
  //   setOverlay(false)
  //   removeScrollLock()
  //   if (typeof window !== "undefined") {
  //     localStorage.removeItem("calendly_result")
  //   }
  // }

  const redirectToExternal = () => {
    window.location.href =
      "https://calendly.com/initial-customer-call/1st-customer-call"
  }

  useEffect(() => {
    if (typeof sessionStorage !== `undefined`) {
      if (sessionStorage.getItem("isProductDemo") === "true") {
        scrollLock()
        document.querySelector(".btn-schedule").click()
        setTimeout(() => {
          sessionStorage.setItem("isProductDemo", false)
        }, 100)
      }
    }
  }, [])

  return (
    <>
      {/* <div id="modal" className={overlay ? "overlay_shown" : "overlay_hidden"}>
        <ScheduleDemoModal removeOverlay={closeModal} />
      </div> */}
      {props.bigButton ? (
        <button
          className={classnames(
            "btn btn-schedule btn-white",
            { "btn-black": props.buttonColor === "black" },
            { "btn-orange": props.buttonColor === "orange" },
            { "btn-blue": props.buttonColor === "blue" },
            { "btn-orange-lg": props.buttonLarge === true }
          )}
          onClick={() => {
            // scrollLock(); setOverlay(true);
            redirectToExternal()
          }}
        >
          {props.buttonText}
        </button>
      ) : (
        <button
          className={classnames(
            "btn btn-schedule btn-white",
            { "btn-black": props.buttonColor === "black" },
            { "btn-orange": props.buttonColor === "orange" },
            { "btn-blue": props.buttonColor === "blue" }
          )}
          onClick={() => {
            // scrollLock(); setOverlay(true);
            redirectToExternal()
          }}
        >
          {props.menu ? "Book a demo" : "Schedule a demo"}
        </button>
      )}
    </>
  )
}

export default ScheduleADemoButton
