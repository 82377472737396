import axios from "axios"

class Geolocation {
  GEONAMES_USER = "chanfullstack"

  /**
   *
   * @param {*function} onSuccess function to run on success
   * @param {*function} onError function to run on error
   */
  getGeoInfo = (onSuccess, onError) => {
    axios
      .get("https://ipapi.co/json/")
      .then(response => {
        let data = response.data

        typeof onSuccess === "function" && onSuccess(data)
      })
      .catch(error => {
        typeof onError === "function" && onError()
      })
  }

  getGeoInfoByCoord = (onSuccess, onError) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        this._getCountryCodeByCoords(position.coords)
          .then(({ data }) => {
            typeof onSuccess === "function" && onSuccess(data)
          })
          .catch(error => {
            typeof onError === "function" && onError()
          })
      })
    }
  }

  _getCountryCodeByCoords = async coords => {
    return await axios.get(
      `http://api.geonames.org/countryCodeJSON?lat=${coords.latitude}&lng=${coords.longitude}&username=${this.GEONAMES_USER}`
    )
  }
}

export default new Geolocation()
